<template>
  <ion-page>
    <!-- header -->
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start" class="mr-2" @click="$emit('close-modal')">
          <ion-icon :icon="chevronBackOutline"></ion-icon>
        </ion-buttons>
        <ion-label class="fs-3 fw-600 mt-1">
          {{ isSelectDeliveryOverPickup ? $t('delivery_date_time') : $t('self_pickup_date_time') }}
        </ion-label>
      </ion-toolbar>
    </ion-header>

    <ion-content v-if="!isLoadingRef">
      <div>
        <div class="date mt-2 pa-2">
          <ion-label class="fs-3 text-uppercase" color="grey">
            {{ isSelectDeliveryOverPickup ? $t('select_delivery_date') : $t('select_pickup_date') }}
          </ion-label>
        </div>
        <ion-slides class="slides-cate" :options="slideOpts">
          <ion-slide
            v-for="(deliveryTimeSlot, index) in deliveryTimeSlots"
            :key="index"
            :class="!deliveryTimeSlot.isAvailable ? 'not-available' : ''"
          >
            <div
              :class="[
                'd-flex flex-column align-center',
                !deliveryTimeSlot.isAvailable ? 'disable-click' : ''
              ]"
              @click="selectDate(deliveryTimeSlot)"
            >
              <div class="d-block date" :class="{ active: selectedDate === deliveryTimeSlot.date }">
                <div class="pb-2 fs-3 text-grey">
                  {{ index === 0 ? $t('today') : formatDay(deliveryTimeSlot.date) }}
                </div>
                <ion-text>
                  {{ formatDateMonth(deliveryTimeSlot.date) }}
                </ion-text>
              </div>
              <ion-text class="fs-1">{{ !deliveryTimeSlot.isAvailable ? $t('unavailable') : '' }}</ion-text>
            </div>
          </ion-slide>
        </ion-slides>
      </div>
      <ion-label class="fs-3 text-uppercase pt-4 pl-2" color="grey">
        {{ isSelectDeliveryOverPickup ? $t('select_delivery_time') : $t('select_pickup_time') }}
      </ion-label>
      <ion-grid class="time pa-2">
        <ion-row>
          <ion-col>
            <ion-radio-group :value="selectedTime">
              <ion-item
                lines="none"
                class="ion-no-padding mb-1"
                v-model="selectedTime"
                v-for="(time, index) in deliveryTimes"
                :key="index"
                @click="selectTime(time)"
              >
                <div slot="start" class="d-flex flex-column">
                  <div class="d-flex flex-row">
                    <ion-label class="mr-3 mt-1" color="dark">
                      <div>{{ time }}</div>
                    </ion-label>
                    <div
                      v-if="isTimeSlotPassedCutoffTime(selectedDate, time)"
                      class="d-flex flex-row box-label align-center fs-13 pl-1 pr-1"
                    >
                      <ion-icon class="mr-1 md icon-small" size="small" :icon="warningOutline"></ion-icon>
                      <ion-text>{{ $t('past_cut_off_time') }}</ion-text>
                    </div>
                  </div>
                  <ion-text
                    v-if="isTimeSlotPassedCutoffTime(selectedDate, time) && selectedTime === time"
                    class="mt-2 text-primary"
                    >{{ $t('no_guaranted_delivery') }}</ion-text
                  >
                </div>
                <ion-radio
                  mode="ios"
                  slot="end"
                  :value="time"
                  :disabled="!isTimeSlotAvailable(selectedDate, time)"
                ></ion-radio>
              </ion-item>
            </ion-radio-group>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>

    <ion-footer v-if="!isLoadingRef">
      <ion-toolbar class="pa-2">
        <ion-button color="primary" expand="block" @click="confirm()" :disabled="!selectedTime">
          <span>{{ $t('confirm') }}</span>
        </ion-button>
      </ion-toolbar>
    </ion-footer>

    <ion-loading
      :is-open="isLoadingRef"
      cssClass="my-custom-class"
      message="Please wait..."
      @didDismiss="setOpen(false)"
    >
    </ion-loading>
  </ion-page>
</template>

<script>
import dayjs from 'dayjs';
import { defineComponent, inject, onMounted, ref } from 'vue';
import { openToast } from '@/modules/shared/utils/toast';
import { executeApolloClient } from '@/services/shared/apollo-client';
import { getAvailableDeliverySlots } from '@/services/shared/graphql';
import { useDateFormatter } from '@/usecases/global';
import { chevronBackOutline, warningOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'delivery-date-time',
  emits: ['confirm', 'close-modal'],
  props: {
    deliveryDate: {
      type: String,
      default: ''
    },
    deliveryTime: {
      type: String,
      default: ''
    },
    currentCartItems: {
      type: Array,
      default: new Array([])
    },
    isNewOrder: {
      type: Boolean,
      default: false
    },
    isSelectDeliveryOverPickup: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { formatDay, formatDateMonth, formatDatePayload } = useDateFormatter();
    const storage = inject('$storage');
    const slideOpts = {
      initialSlide: 0,
      speed: 400,
      slidesPerView: 'auto'
    };

    const deliveryTimes = ref([]);
    const deliveryTimeSlots = ref([]);
    const selectedDate = ref(props.deliveryDate);
    const selectedTime = ref(props.deliveryTime);
    const finalSelection = ref({
      deliveryDate: selectedDate.value ? formatDatePayload(selectedDate.value) : '',
      deliveryTime: selectedTime.value
    });
    const isLoadingRef = ref(true);
    const setOpen = (state) => (isLoadingRef.value = state);

    const selectDate = (deliveryTimeSlot) => {
      selectedDate.value = deliveryTimeSlot.date;
      deliveryTimes.value = deliveryTimeSlot.slots.map(({ label }) => label);

      if (selectedDate.value === finalSelection.value.deliveryDate) {
        selectedTime.value = finalSelection.value.deliveryTime;
      } else {
        selectedTime.value = null;
      }
    };

    const selectTime = (time) => {
      selectedTime.value = time;
      finalSelection.value = {
        deliveryDate: selectedDate.value,
        deliveryTime: selectedTime.value
      };
    };

    const confirm = () => {
      emit('confirm', finalSelection.value);
    };

    const isTimeSlotAvailable = (date, time) => {
      let isAvailable = false;
      if (!time) return isAvailable;

      for (const ds of deliveryTimeSlots.value) {
        if (ds.date === date) {
          for (const slot of ds.slots) {
            if (slot.label === time) {
              isAvailable = slot.isAvailable;
              break;
            }
          }
        }
      }

      return isAvailable;
    };

    const isTimeSlotPassedCutoffTime = (date, time) => {
      let isPassed = false;
      if (!time) return isPassed;

      for (const ds of deliveryTimeSlots.value) {
        if (ds.date === date) {
          for (const slot of ds.slots) {
            if (slot.label === time) {
              isPassed = slot.passedCutoffTime && slot.isAvailable;
              break;
            }
          }
        }
      }

      return isPassed;
    };

    const getAvailableTimeSlots = async (tenantId, customerId) => {
      const items = props.currentCartItems.map((item) => ({
        quantity: item.total_qty || item.quantity || 0,
        sku_id: item.sku_id
      }));
      const payload = {
        userActionDatetime: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
        items,
        tenantId,
        customerId,
        daysLimit: 30,
        isNewOrder: props.isNewOrder
      };
      try {
        const res = await executeApolloClient(getAvailableDeliverySlots, payload);
        deliveryTimeSlots.value = res.getAvailableDeliverySlots;
        deliveryTimes.value = res.getAvailableDeliverySlots
          .find((ds) => ds.date === selectedDate.value)
          ?.slots.map(({ label }) => label);
      } catch (e) {
        openToast(e.message);
      }
    };

    onMounted(async () => {
      const user = await storage.getUser();
      const customer = await storage.getSelectedCompany();
      await getAvailableTimeSlots(user.tenant.id, customer.id);
      isLoadingRef.value = false;
    });

    return {
      deliveryTimeSlots,
      deliveryTimes,
      selectedDate,
      selectedTime,
      chevronBackOutline,
      slideOpts,
      dayjs,
      isLoadingRef,
      setOpen,
      selectDate,
      selectTime,
      confirm,
      isTimeSlotAvailable,
      isTimeSlotPassedCutoffTime,
      warningOutline,
      formatDay,
      formatDateMonth
    };
  }
});
</script>

<style scoped lang="scss">
// organize-imports-ignore
@import '@/assets/css/modal/deliveryTime.scss';
</style>
